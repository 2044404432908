#navbar-header {
  opacity: 0.7;
}
#body {
  position: relative;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 50px;
}
#section1 {
  padding-top: 50px;
  height: 500px;
  color: #fff;
  background-color: #1e85e5;
}
#section2 {
  padding-top: 50px;
  height: 500px;
  color: #fff;
  background-color: #673ab7;
}
#section3 {
  padding-top: 50px;
  height: 500px;
  color: #fff;
  background-color: #ff9800;
}
#section41 {
  padding-top: 50px;
  height: 500px;
  color: #fff;
  background-color: #00bcd4;
}
#section42 {
  padding-top: 50px;
  height: 500px;
  color: #fff;
  background-color: #009688;
}
.logo {
  max-width: 500px;
  max-height: 500px;
  width: 300px;
}

.navbar-collapse {
  flex-grow: 0 !important;
}

.box-col-img-in {
  height: 190px;
  margin: 50px 25px;
  width: 180px;
  overflow: hidden;
}

.box-col-img-in .img-fluid {
  height: 100%;
}
.bg-dark {
  background-color: rgba(255, 255, 255, 0.8) !important;
  color: black;
}
.navbar-dark .navbar-nav .nav-link {
  color: #000000;
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #ad1d58;
}

.navbar-nav .nav-link {
  padding-left: 5px;
  padding-right: 5px;
}
