#wiooh h1 {
    font-size: 1.5rem;
    color: rgb(173, 29, 88);
    padding: 40px 20px
}

#wiooh {
    margin-top: 3rem;
}

#wiooh a {
    font-weight: bold;
    color: rgb(173, 29, 88);
}

#wiooh a:hover {
    text-decoration: underline;
}